* {
  scroll-behavior: smooth;
}

.topnav .nav-item.active>a {
  color: rgba(68, 186, 220, 1)!important;
  font-weight: bold !important;
}

.c-pointer {
  cursor: pointer;
}

.hover-shadow {
  transition: all 0.15s ease-in-out;
}

.hover-shadow:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.was-validated .form-control.invalid {
  border-color: #fa6767;
  padding-right: calc(1.5em + 0.9rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fa6767'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fa6767' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.225rem) center;
  background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}

.was-validated .invalid-feedback {
  display: block;
}

.empty-card-sm {
  height: 8rem !important;
}

.skeleton {
  /* animation: skeleton-loading 1s linear infinite alternate; */
  background-color: var(--bs-body-bg);
  position: relative;
  overflow: hidden;
}

.skeleton::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  -webkit-animation: shimmer 2s infinite;
          animation: shimmer 2s infinite;
  content: "";
}
@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

/* input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
    background: none;
    width: 0;
} */

.inline-input {
  padding: 0;
  border-left: none;
  border-right: none;
  border-radius: 0;
  font-size: inherit;
  text-transform: lowercase;
}

.text-color-inherit {
  color: inherit !important;
}

.hover-underline:hover {
  text-decoration: underline;
}

.rdrDateDisplayWrapper {
  background-color: white !important;
}

.w-35 {
  width: 35% !important;
}

.table-row-overlay-outer {
  position: absolute;
  right: 0;
  height: 100%;
  background: #ffffff69 !important;
  min-width: 21rem !important;
}

.table-row-overlay {
  height: 80%;
  background: #eee !important;
  display: block;
  position: absolute;
  right: 2.5%;
  top: 10%;
  border-radius: 1.5rem;
  max-width: 17rem !important;
  min-width: 17rem !important;
}

.br-15 {
  border-radius: 15rem;
}

.multi-user * {
  margin-left: -10px;
  border: 1px solid #f1f3fa;
  border-radius: 50px;
  cursor: pointer;
}

.multi-user *:first-child {
  margin-left: 0;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.card-hover-prompt {
  opacity: 0;
  position: absolute;
  top: -12%;
  transition: all 0.35s ease;
  font-size: 0.75rem;
  text-transform: none;
  font-weight: normal;
  text-decoration: underline;
  width: 100%;
}

.card-body:hover .card-hover-prompt {
  opacity: 1;
  margin-left: 20px !important;
}

.multi-user .d-inline-block {
  overflow: hidden !important;
}